$(window).on('load', function() {
  $('#nav-toggle').click(function () {
    $('body').toggleClass('open');
  });
});

$(function () {
  $('a[href^="#page"]').click(function () {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    if ($('body').hasClass('open')) {
      $('body').toggleClass('open');
    }
    return false;
  });
});

$(function () {
  var topBtn = $('#pageTop');
  topBtn.hide();
  //スクロールが100に達したらボタン表示
  $(window).on('scroll', function() {
    if ($(this).scrollTop() > 100) {
      //ボタンの表示方法
      topBtn.fadeIn();
    } else {
      //ボタンの非表示方法
      topBtn.fadeOut();
    }
  });
  //スクロールしてトップ
  topBtn.click(function () {
    $('body,  html').animate({
      scrollTop: 0
    }, 500);
    return false;
  });
});

// ページの読み込み時に実行
$(window).on('load', function () {
  setSideHeight();
});

// 画面のリサイズ時に実行
$(window).on('resize', function () {
  setSideHeight();
});

// スクロール時に実行
$(window).on('scroll', function () {
  setSideHeight();
});

function setSideHeight() {
  var w = $(window).width();
  var h = $(window).height();
  if (769 >= w) {
    // 幅 > 高さ の場合のみ
    if (w > h) {
      $('.side.bt').css('min-height', window.innerHeight + 'px');
      $('.side.bt').css('max-height', window.innerHeight + 'px');
    }
  }
}

$(function () {
  $("input[type='number']").each(function () {
    $(this).on('change', function () {
      var val = parseInt($(this).val(), 10);
      if (!isNaN(val)) {
        if (val < 0) {
          $(this).val(0);
        }
      }
    });
  });
});
